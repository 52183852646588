import { Typography } from '@material-ui/core';
import React from 'react';
import { Title } from '../../helpers/components';
import { translate } from '../../helpers/translation';

// Generated by dropping budget into https://www.convertcsv.com/csv-to-json.htm
// And clicking 'CSV to JSON Array' button.
const budgetRows = [
    [
        '',
        'Forecasted Budget',
        'Forecasted Revenue',
        'Net Gain',
        'Actual Expenses'
    ],
    ['Women in Engineering (WIE)', '', '', '', ''],
    ['Wine and cheese', ' $ 3,000.00', ' $-   ', ' $(3,000.00)', ''],
    ['Big eng Little eng', ' $150.00 ', ' $-   ', ' $(150.00)', ''],
    ['Women in tech panel', ' $250.00 ', ' $-   ', ' $(250.00)', ''],
    ['TOTAL', ' $3400.00 ', ' $-   ', ' $(3400.00)', ''],
    ['', '', '', '', ''],
    ['Chair', '', '', '', ''],
    ['Deep Learning Workshop', ' $120.00 ', ' $- ', ' $(120.00) ', ''],
    ['Defi Workshop', ' $120.00 ', ' $- ', ' $(120.00) ', ''],
    ['AI Workshop', ' $120.00 ', ' $- ', ' $(120.00) ', ''],
    ['TOTAL', ' $360.00   ', ' $-   ', ' $(360.00) ', ''],
    ['', '', '', '', ''],
    ['Academic', '', '', '', ''],
    ['Cookies and cram x 2', ' $200.00 ', ' $-   ', ' $(200.00) ', ''],
    ['ELG Panel', ' $200.00 ', ' $-   ', ' $(200.00) ', ''],
    ['Well-being Worshop(virtual)', ' $50.00 ', ' $-   ', ' $(50.00) ', ''],
    ['TOTAL', ' $450.00 ', ' $-   ', ' $(450.00)', ''],
    ['', '', '', '', ''],
    ['Internal', '', '', '', ''],
    [
        'Battle royale 14(Only applies they cannot find funding)',
        ' $- ',
        '',
        ' $-',
        ''
    ],
    ['TOTAL', ' $- ', ' $-   ', ' $-', ''],
    ['', '', '', '', ''],
    ['General', '', '', '', ''],
    ['Comms total expenses', ' $510.00 ', '', '$(510.00)', ''],
    ['Domain renewal (Webmaster)', ' $19.20 ', '', '$(19.20)', ''],
    [
        'Zoom Standard Pro Annual Membership',
        ' $226.00 ',
        '',
        '$(226.00)',
        '$226.00'
    ],
    [
        'Welcome week - IEEE Scavenger hunt',
        ' $50.00 ',
        '',
        '$(50.00)',
        '$50.00'
    ],
    ['ESS levy', ' ', '$3737.11', ' $3737.11 ', ''],
    ['TOTAL', ' $805.20   ', ' $3737.11   ', ' $2931.91  ', '$276.00'],
    ['', '', '', '', ''],
    ['Social', '', '', '', ''],
    ['Magician', ' $500.00 ', ' $-   ', ' $(500.00)', ''],
    ['uOttawa Case Competition', ' $300.00   ', ' $-   ', ' $(300.00) ', ''],
    ['Movie night', ' $- ', ' $-   ', ' $-', ''],
    ['IEEE mini hackethon', ' $150.00 ', ' $-   ', ' $(150.00)', ''],
    ['TOTAL', ' $950.00   ', ' $-   ', ' $(950.00)  ', ''],
    ['', '', '', '', ''],
    ['McNaughton Center', '', '', '', ''],
    ['Inignia 3.3 mini fridge', ' $270.00 ', ' $-   ', ' $(270.00)', ''],
    ['Microwave(amazon)', ' $207.00 ', ' $-   ', ' $(207.00) ', ''],
    [
        'Office renovations and paint supplies',
        ' $650.00 ',
        ' $-   ',
        ' $(650.00) ',
        ''
    ],
    [
        '10 textbooks for the office (calc 1;2;3, Comp arch 1/2, digital systems)',
        ' $1000.00 ',
        ' $-   ',
        ' $(1000.00)',
        ''
    ],
    [
        'New office computer(building from scractch)',
        ' $950.00 ',
        ' $-   ',
        ' $(950.00)',
        ''
    ],
    ['Merch to sell', ' $400.00 ', ' $-   ', ' $(400.00)', ''],
    ['Misc office supplies', ' $250.00 ', ' $-   ', ' $(250.00)', ''],
    ['Raspberry pie', ' $200.00 ', ' $-   ', ' $(200.00)', ''],
    [
        'Tv for the office (General tv for the office)',
        ' $250.00 ',
        ' $-   ',
        ' $(250.00)',
        ''
    ],
    ['Coffee machine and pods', ' $200.00 ', ' $-   ', ' $(200.00)', ''],
    ['TOTAL', ' $4,377.00 ', ' $-   ', ' $(4,377.00)', ''],
    ['', '', '', '', ''],
    [
        '',
        'TOTAL FORECASTED BUDGET',
        'Total Forecasted Revenue',
        'Total Net Gain',
        'Actual expenses'
    ],
    ['', ' $(10,342.20) ', ' $3,737.11 ', ' $(6,605.09) ', '276.00']
];

const Budget = () => (
    <div>
        <Title
            variant="h5"
            gutterBottom
            className="title"
            style={{ marginBottom: '16px' }}
        >
            {translate('Budget')}
        </Title>
        <div
            style={{
                maxWidth: '1000px',
                overflowX: 'auto',
                margin: '2rem auto'
            }}
        >
            <Typography>
                <table id="budget-table">
                    {budgetRows.map(row => {
                        const restOfRow = row.slice(1);
                        const heading = restOfRow.every(x => x === '');
                        return (
                            <tr>
                                {row.map(
                                    cell =>
                                        heading ? (
                                            <td style={{ fontSize: '1.3em' }}>
                                                <b>{cell}</b>
                                            </td>
                                        ) : (
                                            <td>{cell}</td>
                                        )
                                )}
                            </tr>
                        );
                    })}
                </table>
            </Typography>
        </div>
        {/* <iframe
            width="100%"
            title="IEEE uOttawa Budget"
            src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSIRkCKPieqdaSemGMWyKDl1zysL1pBABUD9-3Zi-UpdSCX2LSNiA0g4LlXw9YwUt0Aqx5yve16zbM7/pubhtml?gid=1685439230&amp;single=true&amp;widget=true&amp;headers=false"
            style={{ height: '981px' }}
        /> */}
    </div>
);

export default Budget;
